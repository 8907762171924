const roomType = {
	SYSTEM: 'system',
	LOBBY: 'lobby',
	NOTIFY_BONUS: 'BONUS_NOTIFY',
}
const roomName = {
	LOBBY: 'Lobby',
	SYSTEM: 'system',
	NOTIFY_BONUS: 'NotifyBonus',
}

export default Object.freeze({
	roomType,
	roomName,
	roomNameTranslate: {
		[roomName.LOBBY]: 'chatRoom.name.lobby',
		[roomName.NOTIFY_BONUS]: 'chatRoom.name.bonusNotify',
	},
	bonusType: {
		1: 'WIN', // 贏分
		2: 'RATE', // 倍率
	},
	receiveType: {
		HISTORY: 'HISTORY',
		NEW: 'NEW',
	},
})
