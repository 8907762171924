import { io } from 'socket.io-client';
import store from '@/store';
import CONSTANT_CHAT from '~/utils/constant/chat';

const SocketManagerOptions = {
  // path: 'socket.io', // string 在服務器端捕獲的路徑的名稱
  reconnection: true, // boolean 是否自動重新連接
  reconnectionAttempts: 10, // number 放棄之前重新連接嘗試的次數
  reconnectionDelay: 1500, // number 嘗試重新連接（1000）之前最初等待的時間。受+/-影響randomizationFactor，例如，默認的初始延遲將在500到1500ms之間
  randomizationFactor: 0.5, // number  0 <= randomizationFactor <= 1
  reconnectionDelayMax: 5000, // number 兩次重新連接之間要等待的最長時間（5000）。每次嘗試都會使重新連接延遲增加2倍，並如上所述進行隨機分配
  timeout: 180000, // number 發出connect_error 和connect_timeout事件之前的（Number）連接超時（20000）
  autoConnect: false, // boolean 將此值設置為false，則您必須manager.open 在確定合適的時間進行調用
  withCredentials: false,
  // query: '',// 連接名稱空間時發送的其他查詢參數（然後在socket.handshake.query服務器端的對像中找到）
  // parser: '' // 要使用的解析器。默認為Parsersocket.io隨附的的實例。參見socket.io-parser。
};

let instance;
let interval;
export default function singletonSocket() {
  if (instance) return instance;

  let reconnectionAttempts = 0; // connect_error 失敗原因為token過期的重連次數

  // uri 為空時, 表示與當前 domain 相同
  const uri = '';
  const options = {
    // path: 'socket.io', // string 在服務器端捕獲的路徑的名稱
    reconnection: true, // boolean 是否自動重新連接
    // reconnectionAttempts: 10, // number 放棄之前重新連接嘗試的次數
    reconnectionDelay: 1500, // number 嘗試重新連接（1000）之前最初等待的時間。受+/-影響randomizationFactor，例如，默認的初始延遲將在500到1500ms之間
    randomizationFactor: 0.5, // number  0 <= randomizationFactor <= 1
    reconnectionDelayMax: 5000, // number 兩次重新連接之間要等待的最長時間（5000）。每次嘗試都會使重新連接延遲增加2倍，並如上所述進行隨機分配
    timeout: 180000, // number 發出connect_error 和connect_timeout事件之前的（Number）連接超時（20000）
    autoConnect: false, // boolean 將此值設置為false，則您必須manager.open 在確定合適的時間進行調用
    withCredentials: false,
    path: '/service/event/socket',
    auth: {
      authorization: store.getters['member/token'],
    },
    query: { // 連接名稱空間時發送的其他查詢參數（然後在socket.handshake.query服務器端的對像中找到）
      deviceType: store.getters.deviceType,
    },
    // parser: '' // 要使用的解析器。默認為Parsersocket.io隨附的的實例。參見socket.io-parser。
  };
  const socket = io(uri, options);
  // Socket連線 之後 五秒沒打 login 會斷線
  socket.connect();
  socket.on('connect', () => {
    console.log('Socket connected');
    interval = setInterval(() => socket.emit(
      'system.traceSessionState',
      {},
      (error) => {
        this.$message.error(this.$t(error.code));
      },
    ), 300_000);
    store.dispatch('chat/init');
  });

  // 聊天室
  socket.on('chat.receive', (payload) => {
    payload.receiveType = CONSTANT_CHAT.receiveType.NEW;
    store.dispatch('chat/actionAppendMessage', payload);
  });
  // 大獎通知
  socket.on('system.bonus.notify', (payload) => {
    payload.roomName = CONSTANT_CHAT.roomName.NOTIFY_BONUS;
    payload.receiveType = CONSTANT_CHAT.receiveType.NEW;
    store.dispatch('chat/actionAppendMessage', payload);
  });
  // 系統通知
  socket.on('system.personal.notify', (payload, callback) => {
    console.log('notify:', payload);
    const notify = store.state.financial.depositNotify;
    store.dispatch('financial/actionSetDepositNotify', [...notify, payload]);
    store.dispatch('financial/actionQueryAllPlatMoney');
    callback();
  });

  socket.on('disconnect', (reason) => {
    clearInterval(interval);
    if (reason === 'io client disconnect') {
      console.log(reason);
      return false;
    }
    console.log('socket disconnect', reason);
    // socket.disconnect()
    return false;
  });

  socket.on('reconnect', () => {
    clearInterval(interval);
    console.log('socket reconnect');
    reconnectionAttempts = 0;
  });

  socket.on('connect_error', (reason) => {
    clearInterval(interval);
    if (socket.active) {
      // temporary failure, the socket will automatically try to reconnect
      console.warn('Server error, reconnecting...');
    } else {
      // the connection was denied by the server
      // in that case, `socket.connect()` must be manually called in order to reconnect
      console.error('Server rejected, reason:', reason);
      if (reason.message === 'common.unauthorized') {
        reconnectSocketWithNewToken();
      }
    }
  });

  const closeSocket = () => {
    if (socket && socket.connected) {
      socket.close();
      socket.off('login');
      socket.off('disconnect');
      socket.off('reconnect');
      socket.off('ping');
      socket.off('connect_error');
      clearInterval(interval);
    }
    // if (this.WebSocket.HeartsInterval !== null) {
    //   this.ClearSocketHearts();
    // }
  };

  const reconnectSocketWithNewToken = () => {
    const token = store.getters['member/token'];
    if (!token) return;

    if (reconnectionAttempts < 5) {
      reconnectionAttempts += 1;
      console.log('Attempting to reconnect...', reconnectionAttempts);
      options.auth.authorization = token;
      socket.connect();
    } else {
      console.log('Max reconnect attempts reached.');
      socket.disconnect();
    }
  };

  const connectSocketWithNewToken = () => {
    reconnectionAttempts = 0;
    reconnectSocketWithNewToken();
  };

  instance = {
    socket,
    closeSocket,
    connectSocketWithNewToken,
  };
  return instance;
}
